import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { DefaultLayout } from "../../organisms/DefaultLayout";
import * as styles from "../../styles/company/companyoverview.module.scss";
// import { Slides } from "../../atoms/slides/Slides";
import Slides from "../../atoms/newSlides";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { MAP_STYLES } from "../../organisms/company/map-options";
import { graphql, Link } from "gatsby";
import { groupFilesByDirectory } from "../../utils/utils";
import { TitleCover } from "../../atoms/title-cover/TitleCover";
import { LinkMargin2 } from "../../atoms/LinkMargin2";
import mapIcon from "../../images/companyoverview/maker.png";
import OfficeAll from "../../images/companyoverview/officeAll.png";
import { SEO } from "../../atoms/seo/SEO";
import seoImage from "../../images/companyoverview/_kpo072_re8.jpg";

const mapContainerStyle = {
  marginTop: "16px",
  width: "100%",
  height: "500px",
  borderRadius: "30px",
};

const mapContainerStyleSp = {
  marginTop: "16px",
  width: "100%",
  height: "45vh",
  borderRadius: "30px",
};

const mapOptions = {
  styles: MAP_STYLES,
};

enum OverviewAnchor {
  OVERVIEW = "overview",
  OFFICE = "office",
}

export default (params, { location: pathname }) => {
  const images: any = groupFilesByDirectory(params.data);
  const [isWhite, setIsWhite] = useState(true);

  // chromeでアンカーが動かない
  useEffect(() => {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (window.location.hash && isChrome) {
      setTimeout(function () {
        const hash = window.location.hash;
        window.location.hash = "";
        window.location.hash = hash;
      }, 300);
    }

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsWhite(true);
      } else {
        setIsWhite(false);
      }
    });

    const targets = document.querySelectorAll(
      "[data-change-header-color-point]"
    );
    targets.forEach(target => {
      observer.observe(target);
    });
  }, []);

  return (
    <DefaultLayout isWhite={isWhite} className={styles.CompanyOverview}>
      <SEO
        pathname={pathname}
        title="会社概要 | Company Overview"
        description="会社名: スプリーブホールディングス株式会社 · 代表者 代表取締役CEO 森武司. 所在地. 〒530-0011 大阪府大阪市北区大深町３番１号グランフロント大阪北館６階"
        image={seoImage}
      />
      <div className={styles.CompanyOverview__title}>
        <TitleCover
          title="Company Overview"
          sub="会社概要"
          background={
            <StaticImage
              src="../../images/companyoverview/mainBackground.png"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          }
        />

        {/* Intersection Observer 用にヘッダーのカラーを変えるポイントを仕込む */}
        <div data-change-header-color-point="data-change-header-color-point" />

        <div className={styles.CompanyOverview__titleButtons}>
          <a
            href={`#${OverviewAnchor.OVERVIEW}`}
            className={styles.CompanyOverview__titleButton}
          >
            <p className={styles.CompanyOverview__titleButtonText}>
              Company Overview
            </p>
            <div className={styles.CompanyOverview__titleButtonArrowIcon} />
          </a>
          <a
            href={`#${OverviewAnchor.OFFICE}`}
            className={styles.CompanyOverview__titleButton}
          >
            <p className={styles.CompanyOverview__titleButtonText}>Office</p>
            <div className={styles.CompanyOverview__titleButtonArrowIcon} />
          </a>
        </div>
      </div>

      <a id={OverviewAnchor.OVERVIEW} />
      <LinkMargin2 />
      <div className={styles.outline}>
        <div>
          <h2>Company Overview</h2>
          <h3>会社概要</h3>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <div className={styles.tableFlex}>
          <h2>名称</h2>
          <p>Suprieve Holdings株式会社（スプリーブホールディングス株式会社）</p>
        </div>
        <div className={styles.tableFlex}>
          <h2>代表者</h2>
          <p>代表取締役 CEO 森 武司</p>
        </div>
        <div className={styles.tableFlex}>
          <h2>設立</h2>
          <p>2005年9月12日</p>
        </div>
        <div className={styles.tableFlex}>
          <h2>事業内容</h2>
          <p>事業持株会社</p>
        </div>
        <div className={styles.tableFlex}>
          <h2>グループ従業員数</h2>
          <p>1660人 (2022年4月現在)</p>
        </div>
        <div className={styles.tableFlex}>
          <h2>グループ企業数</h2>
          <p>7社</p>
        </div>
        <div className={styles.tableFlex}>
          <h2>資本金</h2>
          <p>5,000万円</p>
        </div>
        <div className={styles.tableFlex}>
          <h2>連結売上高</h2>
          <p>120億（2021年7月1日〜2022年6月30日）</p>
        </div>
        <div className={styles.tableFlex}>
          <h2>大阪本社</h2>
          <p>
            〒530-0011
            <br />
            大阪府大阪市北区大深町3番1号 グランフロント大阪　北館6階
            <br />
            TEL:06-7639-1480
          </p>
        </div>
        <div className={styles.tableFlex}>
          <h2>東京本社</h2>
          <p>
            〒107-0062
            <br />
            東京都港区南青山1丁目12-3 LIFORK MINAMI AOYAMA S215
            <br />
            TEL:03-6706-2861
          </p>
        </div>
        <div className={styles.tableFlex}>
          <h2>支社</h2>
          <p>
            福岡支社
            <br />
            〒812-0011 福岡県福岡市博多区博多駅前3-4-25
            アクロスキューブ博多駅前601
            <br />
            名古屋支社
            <br />
            〒450-6321 愛知県名古屋市中村区名駅1-1-1 JPタワー名古屋21階
            <br />
            札幌支社
            <br />
            〒060-0004 北海道札幌市中央区北四条西4丁目1-7 MMS札幌駅前ビル1-3F
            <br />
            仙台支社
            <br />
            〒980-0013 宮城県仙台市青葉区花京院1-2-15 ソララプラザ 3F
            <br />
            広島支社
            <br />
            〒730-0016 広島県広島市中区幟町13-15新広島ビルディング1F-2F
          </p>
        </div>
        <div className={styles.tableFlex}>
          <h2>営業所</h2>
          <p>
            東京営業所
            <br />
            〒107-0052 東京都港区赤坂7丁目6-43-301
            <br />
            枚方営業所
            <br />
            〒573-0071 大阪府枚方市茄子作二丁目23-8
          </p>
        </div>
        <div className={styles.tableFlex}>
          <h2>主要取引銀行</h2>
          <p>
            三菱UFJ銀行
            <br />
            三井住友銀行
            <br />
            みずほ銀行
            <br />
            りそな銀行
            <br />
            紀陽銀行
          </p>
        </div>
      </div>

      <div className={styles.CompanyOverview__officesInJapanMap}>
        <a id={OverviewAnchor.OFFICE} />
        <LinkMargin2 />
        <h2 className={styles.CompanyOverview__officesInJapanMapTitle}>
          Office
        </h2>
        <h3 className={styles.CompanyOverview__officesInJapanMapSubTitle}>
          拠点
        </h3>
        <img
          className={styles.CompanyOverview__officesInJapanMapImage}
          src={OfficeAll}
          alt="オフィスの地図"
        />
      </div>

      {/*<LoadScript googleMapsApiKey="AIzaSyDYkQTbr5t0VtcktozfJ28_R1n33uGehXU">*/}
      <LoadScript googleMapsApiKey="AIzaSyBbgVMdofXY_NXbEWH2TR4JeO-LdNMmB3k">
        <div className={styles.CompanyOverview__officeAreas}>
          <div className={styles.CompanyOverview__officeArea}>
            <h2 className={styles.CompanyOverview__officeAreaTitle}>
              Osaka head
            </h2>

            <Slides
              keyPrefix="osaka"
              images={images.osaka.edges.map(({ node }) => node)}
              className={styles.CompanyOverview__officeAreaSlide}
            />

            <div className={styles.CompanyOverview__officeAreaAddress}>
              <h3 className={styles.CompanyOverview__officeAreaName}>
                大阪本社
              </h3>
              <p className={styles.CompanyOverview__officeAreaAddressText}>
                〒530-0011 大阪府大阪市北区大深町3番1号 グランフロント大阪
                北館6階
                <br />
                TEL:06-7639-1480
              </p>

              <div className="pc-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: 34.70546806144684,
                    lng: 135.49442093655205,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 34.70546806144684,
                      lng: 135.49442093655205,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>
              <div className="sp-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyleSp}
                  center={{
                    lat: 34.70546806144684,
                    lng: 135.49442093655205,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 34.70546806144684,
                      lng: 135.49442093655205,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>
            </div>
          </div>

          <div className={styles.CompanyOverview__officeArea}>
            <h2 className={styles.CompanyOverview__officeAreaTitle}>
              Tokyo head
            </h2>

            <Slides
              keyPrefix="tokyo"
              images={images.tokyo.edges.map(({ node }) => node)}
              className={styles.CompanyOverview__officeAreaSlide}
            />

            <div className={styles.CompanyOverview__officeAreaAddress}>
              <h3 className={styles.CompanyOverview__officeAreaName}>
                東京本社
              </h3>
              <p className={styles.CompanyOverview__officeAreaAddressText}>
                〒107-0062 東京都港区南青山1丁目12-3 LIFORK MINAMI AOYAMA S215
                <br />
                TEL:03-6706-2861
              </p>
              <div className="pc-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: 35.6689073,
                    lng: 139.7231499,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 35.6689073,
                      lng: 139.7231499,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>
              <div className="sp-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyleSp}
                  center={{
                    lat: 35.6689073,
                    lng: 139.7231499,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 35.6689073,
                      lng: 139.7231499,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>
            </div>
          </div>

          <div className={styles.CompanyOverview__officeArea}>
            <h2 className={styles.CompanyOverview__officeAreaTitle}>Fukuoka</h2>

            <Slides
              keyPrefix="fukuoka"
              images={images.fukuoka.edges.map(({ node }) => node)}
              className={styles.CompanyOverview__officeAreaSlide}
            />

            <div className={styles.CompanyOverview__officeAreaAddress}>
              <h3 className={styles.CompanyOverview__officeAreaName}>
                福岡支社
              </h3>
              <p className={styles.CompanyOverview__officeAreaAddressText}>
                〒810-0001 福岡県福岡市中央区天神1丁目1-1 fabbit Global Gateway
                ACROS Fukuoka
              </p>
              <div className="pc-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: 33.5914542,
                    lng: 130.4002467,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 33.5914542,
                      lng: 130.4002467,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>
              <div className="sp-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyleSp}
                  center={{
                    lat: 33.5914542,
                    lng: 130.4002467,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 33.5914542,
                      lng: 130.4002467,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>
            </div>
          </div>

          <div className={styles.CompanyOverview__officeArea}>
            <h2 className={styles.CompanyOverview__officeAreaTitle}>Nagoya</h2>

            <Slides
              keyPrefix="nagoya"
              images={images.nagoya.edges.map(({ node }) => node)}
              className={styles.CompanyOverview__officeAreaSlide}
            />

            <div className={styles.CompanyOverview__officeAreaAddress}>
              <h3 className={styles.CompanyOverview__officeAreaName}>
                名古屋支社
              </h3>
              <p className={styles.CompanyOverview__officeAreaAddressText}>
                〒450-6321 愛知県名古屋市中村区名駅1-1-1 JPタワー名古屋21階
                <br />
              </p>

              <div className="pc-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: 35.1729562,
                    lng: 136.8802953,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 35.1729562,
                      lng: 136.8802953,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>

              <div className="sp-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyleSp}
                  center={{
                    lat: 35.1729562,
                    lng: 136.8802953,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 35.1729562,
                      lng: 136.8802953,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>
            </div>
          </div>

          <div className={styles.CompanyOverview__officeArea}>
            <h2 className={styles.CompanyOverview__officeAreaTitle}>Sapporo</h2>

            <Slides
              keyPrefix="sapporo"
              images={images.sapporo.edges.map(({ node }) => node)}
              className={styles.CompanyOverview__officeAreaSlide}
            />

            <div className={styles.CompanyOverview__officeAreaAddress}>
              <h3 className={styles.CompanyOverview__officeAreaName}>
                札幌支社
              </h3>
              <p className={styles.CompanyOverview__officeAreaAddressText}>
                〒060-0004 北海道札幌市中央区北四条西4丁目1-7
                MMS札幌駅前ビル1-3F
                <br />
              </p>

              <div className="pc-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: 43.0655844,
                    lng: 141.3501695,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 43.0655844,
                      lng: 141.3501695,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>

              <div className="sp-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyleSp}
                  center={{
                    lat: 43.0655844,
                    lng: 141.3501695,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 43.0655844,
                      lng: 141.3501695,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>
            </div>
          </div>

          <div className={styles.CompanyOverview__officeArea}>
            <h2 className={styles.CompanyOverview__officeAreaTitle}>Sendai</h2>

            <Slides
              keyPrefix="sendai"
              images={images.sendai.edges.map(({ node }) => node)}
              className={styles.CompanyOverview__officeAreaSlide}
            />

            <div className={styles.CompanyOverview__officeAreaAddress}>
              <h3 className={styles.CompanyOverview__officeAreaName}>
                仙台支社
              </h3>
              <p className={styles.CompanyOverview__officeAreaAddressText}>
                〒980-0013 宮城県仙台市青葉区花京院1-2-15 ソララプラザ 3F
                <br />
              </p>

              <div className="pc-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: 38.2635238,
                    lng: 140.8813118,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 38.2635238,
                      lng: 140.8813118,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>

              <div className="sp-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyleSp}
                  center={{
                    lat: 38.2635238,
                    lng: 140.8813118,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 38.2635238,
                      lng: 140.8813118,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>
            </div>
          </div>

          <div className={styles.CompanyOverview__officeArea}>
            <h2 className={styles.CompanyOverview__officeAreaTitle}>
              Hiroshima
            </h2>

            <Slides
              keyPrefix="hiroshima"
              images={images.hiroshima.edges.map(({ node }) => node)}
              className={styles.CompanyOverview__officeAreaSlide}
            />

            <div className={styles.CompanyOverview__officeAreaAddress}>
              <h3 className={styles.CompanyOverview__officeAreaName}>
                広島支社
              </h3>
              <p className={styles.CompanyOverview__officeAreaAddressText}>
                〒730-0016 広島県広島市中区幟町13-15新広島ビルディング1F-2F
                <br />
                TEL:082-512-2230
              </p>

              <div className="pc-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: 34.3937901,
                    lng: 132.4629802,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 34.3937901,
                      lng: 132.4629802,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>

              <div className="sp-only">
                <GoogleMap
                  mapContainerStyle={mapContainerStyleSp}
                  center={{
                    lat: 34.3937901,
                    lng: 132.4629802,
                  }}
                  options={mapOptions}
                  zoom={17}
                >
                  <Marker
                    position={{
                      lat: 34.3937901,
                      lng: 132.4629802,
                    }}
                    icon={mapIcon}
                  />
                </GoogleMap>
              </div>
            </div>
          </div>
        </div>
      </LoadScript>
    </DefaultLayout>
  );
};

export const query = graphql`
  query OfficePicsQuery {
    images: allFile(
      filter: { relativePath: { regex: "/companyoverview/slide/" } }
      sort: { fields: base }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
    }
  }
`;
