import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { FunctionComponent, useEffect, useState } from "react";
import * as style from "./style.module.scss";

type Props = {
  // FIXME: 最終的には string[] で型を付けたい
  images: any[];
  keyPrefix?: string;
  className?: string;
};

type SlideImagesProps = Pick<Props, "images" | "keyPrefix"> & {
  sliderId: string;
};
type SlideImageNumbersProps = Pick<Props, "keyPrefix"> & {
  imageNumbers: string[];
  currentNumber: number;
  setCurrentNumber(nextImageIndex: number): void;
};
type SlideImageNumberProps = Pick<Props, "keyPrefix"> & {
  imageNumber: string;
  isCurrent: boolean;
  setCurrentNumber?(): void;
};

const SlideImageNumber: FunctionComponent<SlideImageNumberProps> = ({
  imageNumber,
  isCurrent,
  keyPrefix = "slide-images",
  setCurrentNumber = () => {},
}) => (
  <li
    className={style.Slides__imageNumber}
    key={`${keyPrefix}-image-number-${imageNumber}`}
  >
    <button
      className={classNames(
        style.Slides__imageNumberButton,
        isCurrent ? style["Slides__imageNumberButton_current"] : ""
      )}
      onClick={() => {
        setCurrentNumber();
      }}
    >
      {imageNumber}
    </button>
  </li>
);

const SlideImageNumbers: FunctionComponent<SlideImageNumbersProps> = ({
  imageNumbers,
  currentNumber,
  keyPrefix = "slide-images",
  setCurrentNumber,
}) =>
  imageNumbers.length > 0 ? (
    <ul className={style.Slides__imageNumbers}>
      {imageNumbers.map((imageNumber, index) => (
        <SlideImageNumber
          keyPrefix={keyPrefix}
          imageNumber={imageNumber}
          isCurrent={currentNumber === index}
          setCurrentNumber={() => {
            setCurrentNumber(index);
          }}
          key={index}
        />
      ))}
    </ul>
  ) : null;

const SlideImages: FunctionComponent<SlideImagesProps> = ({
  sliderId,
  images,
  keyPrefix = "slide-images",
}) => {
  return images.length > 0 ? (
    <ul id={sliderId} className={style.Slides__images}>
      {images.map((image, index) => {
        const formatedImage = getImage(image);

        return (
          <li className={style.Slides__image} key={`${keyPrefix}-${index}`}>
            {formatedImage ? (
              // FIXME: GatbyImage を使う必要が本当にあるかどうかを確認する
              <GatsbyImage
                image={formatedImage}
                alt={`${keyPrefix}-${index}`}
                className={style.Slides__imageBody}
              />
            ) : null}
          </li>
        );
      })}
    </ul>
  ) : null;
};

const Slides: FunctionComponent<Props> = ({
  keyPrefix,
  images,
  className = "",
}) => {
  const SLIDER_ID = `${keyPrefix}-slider`;
  const [currentNumber, setCurrentNumber] = useState(0);
  const imageNumbers: string[] = [];

  // 番号のみを吸い出した配列が欲しいだけなので　forEach / for in / for of は使わない
  for (let number = 1; number <= images.length; number++) {
    if (number < 10) {
      // 9 より小さい場合は先頭に 0 をつける
      imageNumbers.push(`0${number}`);
    } else {
      imageNumbers.push(`${number}`);
    }
  }

  const scrollToCurrentImage = () => {
    const imageSlider = document.getElementById(SLIDER_ID);

    if (imageSlider) {
      imageSlider.scrollTo({
        // スライダーの幅を取得して指定した番号のスライドへ飛ぶ
        left: currentNumber * imageSlider.clientWidth,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToCurrentImage();
  }, [currentNumber]);

  return (
    <div className={classNames(style.Slides, className)}>
      <div className={style.Slides__items}>
        <div className={style.Slides__upperItem}>
          <SlideImages
            sliderId={SLIDER_ID}
            images={images}
            keyPrefix={keyPrefix}
          />
        </div>
        <div className={style.Slides__lowerItem}>
          <SlideImageNumbers
            imageNumbers={imageNumbers}
            keyPrefix={keyPrefix}
            currentNumber={currentNumber}
            setCurrentNumber={nextImageIndex => {
              setCurrentNumber(nextImageIndex);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Slides;
