// extracted by mini-css-extract-plugin
export var CompanyOverview = "h_bs";
export var CompanyOverview__title = "h_bt";
export var CompanyOverview__titleButtons = "h_bv";
export var CompanyOverview__titleButton = "h_bw";
export var CompanyOverview__titleButtonArrowIcon = "h_bx";
export var CompanyOverview__titleButtonText = "h_by";
export var CompanyOverview__officesInJapanMap = "h_bz";
export var CompanyOverview__officesInJapanMapTitle = "h_bB";
export var CompanyOverview__officesInJapanMapSubTitle = "h_bC";
export var CompanyOverview__officesInJapanMapImage = "h_bD";
export var CompanyOverview__officeAreas = "h_bF";
export var CompanyOverview__officeArea = "h_bG";
export var CompanyOverview__officeAreaTitle = "h_bH";
export var CompanyOverview__officeAreaSlide = "h_bJ";
export var CompanyOverview__officeAreaAddress = "h_bK";
export var Googlemap = "h_bL";
export var CompanyOverview__officeAreaName = "h_bM";
export var CompanyOverview__officeAreaAddressText = "h_bN";
export var outline = "h_bP";
export var tableContainer = "h_bQ";
export var tableFlex = "h_bR";
export var table = "h_bS";
export var head = "h_bT";
export var coverOsaka = "h_bV";
export var coverTokyo = "h_bW";
export var coverFukuoka = "h_bX";
export var coverNagoya = "h_bY";
export var officeImg = "h_bZ";
export var carouselContainer = "h_b0";
export var btnFloat = "h_b1";
export var test1 = "h_b2";
export var btnChange = "h_g";
export var test2 = "h_b3";
export var btnTransform = "h_b4";