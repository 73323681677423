import {groupBy} from "lodash";

export const groupFilesByDirectory = data => {
    const images = groupBy(data.images.edges, node => {
        const paths = node.node.relativePath.split('/');
        paths.pop(); // filename
        return paths.pop(); // parent directory
    });
    const response = {};
    for (const key in images) {
        response[key] = {edges: images[key]};
    }
    return response;
};

export const sleep = n => new Promise(resolve => setTimeout(resolve, n));

export const customDebounce = (f, ms) => {
    let _t = Date.now();
    return (...args) => {
        const t = Date.now();
        if (t - _t > ms) {
            f(...args);
            _t = t;
        }
    }
};
