// extracted by mini-css-extract-plugin
export var Slides = "C_l8";
export var Slides__items = "C_l9";
export var Slides__upperItem = "C_mb";
export var Slides__lowerItem = "C_mc";
export var Slides__images = "C_md";
export var Slides__image = "C_mf";
export var Slides__imageBody = "C_mg";
export var Slides__imageNumbers = "C_mh";
export var Slides__imageNumber = "C_mj";
export var Slides__imageNumberButton = "C_mk";
export var Slides__imageNumberButton_current = "C_ml";