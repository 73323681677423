export const MAP_STYLES = [
    {
        "stylers": [
            {
                "saturation": -100
            }
        ]
    },
    {
        "elementType": "labels.text",
        "stylers": [
            {
                "saturation": -100
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "stylers": [
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "lightness": 60
            }
        ]
    },
    {
        "featureType": "poi.school",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "stylers": [
            {
                "lightness": -5
            }
        ]
    },
    {
        "featureType": "transit.line",
        "stylers": [
            {
                "lightness": -10
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "water",
        "stylers": [
            {
                "color": "#b9cddf"
            },
            {
                "saturation": -45
            }
        ]
    }
]
